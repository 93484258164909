import React from 'react'

export const BlogLayout = ({ children }) => {
  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-8 mb-6">
            {children}
          </div></div></div></div>
  )
}
