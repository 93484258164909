import React from "react";
import { graphql, Link } from "gatsby";
import { BlogLayout } from "../components/layout/blog-layout";
import { SEO } from "../components/seo";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { Home, Folder } from "react-feather";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2>{children}</h2>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target;
      return (
        // container is-fullhd is-flex is-justify-content-center mt-2 mb-3 is-flex-direction-column
        <div className="">
          <GatsbyImage image={getImage(gatsbyImageData)} alt={description} imgClassName="align-self-center" />
          {description && <p className="is-size-6 has-text-left is-italic has-text-grey-lighter">{description}</p>}
        </div>
      );
    },
  },
};

const PostTemplate = (props) => {
  const post = props.data.contentfulPosts;
  return (
    <div className="post bkg-black">
      < SEO title={post.title} description={post.shortDescription} />
      {/* <BlockText /> */}
      < BlogLayout >
        <div className="flex flex-column mb-5">
          <Link to="/" state={{ tab: "Home" }}>
            <button className="button is-danger is-outlined is-small-mobile">
              <Home size="1em" className="m-0" />
            </button>
          </Link>
          <Link to="/" state={{ tab: "Posts" }}>
            <button className="button is-danger is-outlined is-small-mobile ml-2">
              <Folder size="1em" /> All articles
            </button>
          </Link>
        </div>

        <p>Posted on: {post.updatedAt}</p>
        <h1>{post.title}</h1>
        {post.post && renderRichText(post.post, options)}
      </BlogLayout >
    </div >
  );
};

export default PostTemplate;

export const query = graphql`
  query postsQuery($id: String!) {
    contentfulPosts(id: { eq: $id }) {
      id
      title
      shortDescription
      post {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(placeholder: BLURRED)
            __typename
          }
        }
      }
      updatedAt(formatString: "DD-MM-yyyy")
    }
  }
`;
